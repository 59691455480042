import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'



const HeaderTwo = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute z-[15] w-full'>
            <div className='flex'>
                <div className='w-full mr-0 md:w-[35%] lg:w-[18%] bgBloque'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[90%] mx-auto'
                    />
                </div>

                <div className='hidden md:flex w-[65%] lg:w-[72%] mx-auto justify-center items-center bg-white'>
                    <div className='flex space-x-3 w-full mx-auto justify-center'>
                        <div className='hidden w-[40%] text-[#e60104] border-r-[2px] border-dotted border-black lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BiPhoneCall  fontSize={35} />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <p className='-mb-3'>Phone Number</p>
                                    <h5 className='text-[21px]'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className='hidden w-[30%] text-[#e60104] border-r-[2px] border-dotted border-black bp lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsCalendar4Range  fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3'>Workdays</p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                </h5>
                            </div>
                        </div>
                        <div className='hidden w-[40%] text-[#e60104]  lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory  fontSize={35} />
                            </div>
                            <div>
                                <p className='-mb-3'>Work Hours</p>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden bg-[#efb810] md:flex w-[35%] lg:w-[18%] mx-auto justify-center items-center  bg-center bg-contain bg-no-repeat' style={{backgroundImage:`url("https://firebasestorage.googleapis.com/v0/b/imagenes-4d2f6.appspot.com/o/Geral_Mesa%20de%20trabajo%201.png?alt=media&token=f754db7f-aee3-413f-9e24-aa5c2b84ab11")`}}>

                </div>

            </div>

            <div className='bgBloque px-3 py-4'>
                <div className='flex justify-between md:justify-center items-center'>
                    <nav>
                        <Navmenu />
                    </nav>
                    <div className='block md:hidden'>
                        <ButtonContent/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo