import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import CounterUp from '../global/CounterUp'
import Values from "../About/Values";
import Map from "../Contact/MapContent";

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const imgHeader=[
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fstock%2FM-K%20Contracting%20LLC-2022-11-21T20%3A57%3A12.715Z-0.jpg?alt=media&token=e8dce9c1-a043-463d-81b4-04169bdcb237',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fgallery%2FM-K%20Contracting%20LLC-2022-11-21T20%3A04%3A58.179Z-83.jpg?alt=media&token=1666dd60-fe83-4b1f-a1ac-d792b5410182',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fgallery%2FM-K%20Contracting%20LLC-2022-11-21T20%3A04%3A58.179Z-81.jpg?alt=media&token=0d0ea316-8e43-47a0-b82e-3001be40a14c',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fgallery%2FM-K%20Contracting%20LLC-2022-11-21T20%3A04%3A58.179Z-83.jpg?alt=media&token=1666dd60-fe83-4b1f-a1ac-d792b5410182',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fstock%2FM-K%20Contracting%20LLC-2022-11-21T20%3A57%3A12.715Z-1.jpg?alt=media&token=02b54238-894a-4fa0-8b06-00cf0faf8cee',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fgallery%2FM-K%20Contracting%20LLC-2022-11-21T20%3A04%3A58.177Z-63.jpg?alt=media&token=2278d6f8-7c64-4822-8fd5-7c206a95ac79',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637bd8fa14d762556e899ca3%2Fgallery%2FM-K%20Contracting%20LLC-2022-11-21T20%3A04%3A58.171Z-22.jpg?alt=media&token=741a50a9-fcd0-41bc-a140-66e51867143c'

    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replace(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    bgimg={imgHeader[index]}
                                    headertitle={item.name}
                                    Subheader={'Our Services'}
                                />
                                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5">
                                    <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5">
                                        <img
                                            src={item.description[0].img}
                                            alt='no found'
                                            className="w-full object-cover h-auto md:h-[500px]"
                                        />
                                        <h3>
                                            {
                                                rpdata?.dbSlogan[Math.floor(Math.random() * rpdata?.dbSlogan?.length)].slogan
                                            }
                                        </h3>
                                        {
                                            item.description.map((item, index) => {
                                                return (
                                                    <p key={index}>{item.text} <br /> <br /></p>

                                                )
                                            })
                                        }
                                        <ButtonContent />
                                    </div>
                                    <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5">
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white"> Our Services</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbServices?.slice(0, 7).map((nam, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${nam.name.replace(" ", "-").toLowerCase()}`}
                                                                    onClick={goToTop}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <FaCaretRight />
                                                                    <span className="pl-2">
                                                                        {nam.name}
                                                                    </span>
                                                                </Link>
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white">Contact</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                        return (
                                                            <li key={index} className='flex items-center text-white pb-3'>
                                                                <a
                                                                    href={`tel:+1${phone.phone}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <BiPhoneCall />
                                                                    <span className="pl-2">
                                                                        {phone.phone}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className='flex items-center text-white pb-3'
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <MdOutlineEmail />
                                                                    <span className="pl-2">
                                                                        {email.email}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start text-white">Opening Hours</h4>
                                            <ul className="px-1 md:px-4">
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <BiCalendar />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                    </span>
                                                </li>
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <FaRegClock />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            rpdata?.dbSocialMedia?.redes?.length > 1 ?
                                                <div className="pb-5">
                                                    <h4 className="text-center md:text-start text-white">Follow Us</h4>
                                                    <SocialMedia />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp image={rpdata?.gallery?.[6]} />

            <div className="mt-[-110px]">
                <Values />
            </div>
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;